<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <router-view />
    <b-row class="hide-on-mobile">
      <b-col cols="1"></b-col>
      <b-col><div>
        <span class="float-md-left d-block d-md-inline-block mt-25">
      Daikin Airconditioning (Singapore) Pte. Ltd.
      </span>
      </div>
      </b-col>
      <b-col cols="1"></b-col>
      
      <b-col><div>
        <span class="float-md-right d-none d-md-block"> Powered by <a href="https://trillium-tech.com/" target="_blank">Trillium Technologies</a></span>
      </div></b-col>
      <b-col cols="1"></b-col>
    </b-row>

    <b-row class="hide-on-desktop">
      <!-- <b-col cols="1"></b-col> -->
      <b-col cols="12" class="text-center mb-2"><div>
        <span>
      Daikin Airconditioning (Singapore) Pte. Ltd.
      </span>
      </div>
      </b-col>
      
      
      <b-col cols="12" class="text-center"><div>
        <span> Powered by <a href="https://trillium-tech.com/" target="_blank">Trillium Technologies</a></span>
      </div></b-col>
      <!-- <b-col cols="1"></b-col> -->
    </b-row>
    <br>
    <br>
    
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
}
</script>

<style>
/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}
</style>
